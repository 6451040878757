<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
        <h1 class="logo"><a href="javascript:;">
                <img src="../../assets/img/apple-touch-icon.png" /></a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
        <!-- <nav id="navbar" class="navbar">
            <ul>
                <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
                <li><a class="nav-link scrollto" href="#about">About Us</a></li>
                <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
        </nav> -->
        <!-- .navbar -->
    </div>
</header>
<!-- End Header -->
<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
    <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-9 text-center">
                <h1>NewsPod</h1>
                <h2>The world is here.</h2>
            </div>
        </div>
        <div class="row icon-boxes">
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                data-aos-delay="200">
                <div class="icon-box">
                    <div class="icon"><i class="ri-stack-line"></i></div>
                    <h4 class="title"><a href="javascript:;">Latest News</a></h4>
                    <p class="description">Latest news from all over India from the reliable sources.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                data-aos-delay="300">
                <div class="icon-box">
                    <div class="icon"><i class="ri-palette-line"></i></div>
                    <h4 class="title"><a href="javascript:;">Personalised News</a></h4>
                    <p class="description">Choose your preferred language & location and get News according to it.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                data-aos-delay="400">
                <div class="icon-box">
                    <div class="icon"><i class="ri-command-line"></i></div>
                    <h4 class="title"><a href="javascript:;">Read Later</a></h4>
                    <p class="description">Mark your interesting News to Read Later and read it at your free time.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                data-aos-delay="500">
                <div class="icon-box">
                    <div class="icon"><i class="ri-fingerprint-line"></i></div>
                    <h4 class="title"><a href="javascript:;">Save to Bundle</a></h4>
                    <p class="description">Create your own Bundle and save interesting News into it.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Hero -->
<main id="main">
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
        <div class="container" data-aos="fade-up">
            <div class="section-title">
                <h2>About Us</h2>
                <p>NewsPod - The top–rated news aggregator in the App Store, delivering all the news stories that
                    matter to you. Easy to use, hard to give up – News Pod offers a top-notch user experience with
                    its award-winning design and intuitive interface.</p>
            </div>
            <div class="row content">
                <div class="col-lg-6">
                    <ul>
                        <li><i class="ri-check-double-line"></i> All the news across the India – A selection of a
                            plethora of news outlets, sources, online publishers, magazines, newspapers, columns,
                            and blogs from all the states of India, available for adding to your collection</li>
                        <li><i class="ri-check-double-line"></i> Personalized and unbiased – Follow news stories
                            from the news outlets only you decide. Read the content of publishers you deem objective
                            and impartial. Create and access the collection of your favorite and most trusted
                            sources via ‘My Bundle’.</li>
                        <li><i class="ri-check-double-line"></i> Breaking news alerts – Get instant push
                            notifications for major developing stories and stay updated.</li>
                        <li><i class="ri-check-double-line"></i> Unparalleled search feature – Our vastly improved
                            search function delivers the best results for both Articles and Sources; bringing you
                            whatever you are looking for just in moments; scanning all the news, blogs, magazines,
                            articles, titles, columnists, and more. The best part? It's free!</li>
                        <li><i class="ri-check-double-line"></i> Offline mode – No internet? We got you covered!
                            Once an article appears in your newsfeed, it will be accessible even if you go offline.
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 pt-4 pt-lg-0">
                    <ul>
                        <li><i class="ri-check-double-line"></i> Reader mode – Not happy with the way a web page
                            displays content? Remove the clutter and focus on the ‘actual’ content instead.</li>

                        <li><i class="ri-check-double-line"></i> Browse content from various topics – Delve into
                            multiple categories and discover the most popular stories: Be it whether business,
                            technology, sports, science, entertainment, opinion, metro, fashion and much more.</li>
                        <li><i class="ri-check-double-line"></i> Discover local stories – Switch between global
                            news, and news from the India</li>
                        <li><i class="ri-check-double-line"></i> Save for later – Interested in reading an article
                            but short on time? Bookmark and read when it is convenient!</li>
                        <li><i class="ri-check-double-line"></i> Daily editorial picks – Check out ‘Daily Bundle’
                            for the most inspiring, enlightening, and empowering content.</li>
                        <li><i class="ri-check-double-line"></i> Photo stories – A constantly updating, curated
                            collection of the most beautiful shots in fashion, lifestyle, and press photography.
                        </li>
                        <li><i class="ri-check-double-line"></i> Audible – don’t want to read the news? your whole
                            news story in audio format too. listen to it while drive car and be updated.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->
    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
            <div class="section-title">
                <h2>Contact</h2>
            </div>
            <div class="row mt-5">
                <div class="col-lg-4">
                    <div class="info">
                        <div class="email"> <i class="bi bi-envelope"></i>
                            <h4>Email:</h4>
                            <p><a href="mailto:support@newspod.in" target="_blank">support@newspod.in</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 mt-5 mt-lg-0">
                    <form [formGroup]="contactUsForm" class="php-email-form" (ngSubmit)="contactUsSubmit()">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name"
                                    required formControlName="contact_user_name">
                                <div *ngIf="submitted && contactUsForm.controls.contact_user_name.errors" class="form-error">
                                    <p *ngIf="contactUsForm.controls.contact_user_name.errors?.required">
                                        Name is required</p>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="email" class="form-control" name="email" id="email"
                                    placeholder="Your Email" required formControlName="contact_user_email">
                                <div *ngIf="submitted && contactUsForm.controls.contact_user_email.errors"
                                    class="form-error">
                                    <p *ngIf="contactUsForm.controls.contact_user_email.errors?.required">
                                        Email is required</p>
                                    <p *ngIf="contactUsForm.controls.contact_user_email.errors?.pattern">
                                        Invalid Email</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject"
                                required formControlName="contact_subject">
                            <div *ngIf="submitted && contactUsForm.controls.contact_subject.errors" class="form-error">
                                <p *ngIf="contactUsForm.controls.contact_subject.errors?.required">
                                    Subject is required</p>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <textarea class="form-control" name="message" rows="5" placeholder="Message" required
                                formControlName="contact_description"></textarea>
                            <div *ngIf="submitted && contactUsForm.controls.contact_description.errors" class="form-error">
                                <p *ngIf="contactUsForm.controls.contact_description.errors?.required">
                                    Message is required</p>
                            </div>
                        </div>
                        <div class="my-3">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div class="text-center">
                            <button type="submit">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- End Contact Section -->
</main>
<!-- End #main -->
<!-- ======= Footer ======= -->
<footer id="footer">
    <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
            <div class="copyright"> &copy; Copyright <strong><span>NewsPod Pvt. Ltd.</span></strong> All Rights
                Reserved </div>
        </div>
    </div>
</footer>
<!-- End Footer -->
<!-- <div id="preloader"></div> -->
<a href="javascript:;" class="back-to-top d-flex align-items-center justify-content-center">
    <i class="bi bi-arrow-up-short"></i>
</a>