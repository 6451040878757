import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CmsPageComponent } from './cms-page/cms-page.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: ':cmsTitle', component: CmsPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
