import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.css']
})
export class CmsPageComponent implements OnInit {

  type: string;
  title = ['About Us', 'Privacy Policy', 'Term & Conditions', 'Contact Us', 'Blog', 'FAQ', 'Disclaimer'];
  encodedTitle = ['About%20Us', 'Privacy%20Policy', 'Term%20&%20Conditions', 'Contact%20Us', 'Blog', 'FAQ', 'Disclaimer'];
  content: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: AppService,
    private headerTitle: Title
  ) {
    /** Reload component forcefully */
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit(): void {
    this.getParams();
  }

  getParams() {
    // on user vise form change
    this.activatedRoute.params.subscribe((params: Params) => {
      this.type = params.cmsTitle;

      let title = this.type.replace(/-/gi, ' ')
      this.getCmsContent(title);
    })
  }

  getCmsContent(title) {
    console.log('title', title);
    this.apiService.getContect(title).subscribe((data) => {
      if (data['status']) {
        this.headerTitle.setTitle(data['data'].cms_title)
        this.content = data['data'].cms_content;
      }
    })
  }

}
