import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'frontend';
  darkMode: Boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {
      const theme = params['theme'];

      if (theme && theme == 'dark_theme') {
        this.darkMode = true
      }
    });
  }
}
