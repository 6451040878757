import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  contactUsForm: FormGroup
  submitted: Boolean = false;

  constructor(
    public _formBuilder: FormBuilder,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.buildForm()
  }

  buildForm() {
    this.contactUsForm = this._formBuilder.group({
      contact_user_name: ['', [Validators.required]],
      contact_user_email: ['', [Validators.required, Validators.pattern(/^[A-Za-z\d._-]{2,64}\@([A-Za-z\d]+)\.[A-Za-z\d]+(.[A-Za-z\d]+)?$/)]],
      contact_subject: ['', [Validators.required]],
      contact_description: ['', [Validators.required]]
    })
  }

  contactUsSubmit() {
    this.submitted = true;
    if(this.contactUsForm.valid) {
      this.toastr.success('Your message has been sent. Thank you!');
      this.contactUsForm.reset()
      this.submitted = false;
    }
  }

}
