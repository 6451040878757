import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    URL: string = environment.api;

    constructor(private http: HttpClient) { }

    getContect(title) {
        return this.http.get(this.URL + '/user/cms/getByTitle/' + title);
    }

}