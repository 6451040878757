<div class="content" role="main" 
[ngClass] = "{'dark_mode': darkMode }">
  <!-- Highlight Card -->
  <!-- <div class="card highlight-card card-small">
   <h2>Coming soon</h2> 
  </div> -->

  <router-outlet></router-outlet>
</div>

